import { useState } from "react";

import Button from "../../atoms/Button";
import Form, { FormProps } from "../../atoms/Form";
import Input from "../../atoms/Input";

import { useNavigate } from "@tanstack/react-location";

import { BsEyeFill } from "react-icons/bs";

export interface AuthFormProps extends FormProps {
  /** 폼 타입(로그인 | 패스워드 찾기) */
  type: "sign-in" | "forgot-passowrd";
}

function AuthForm({ type, ...args }: AuthFormProps) {
  const navigate = useNavigate();

  const [visiblePassword, setVisiblePassword] = useState(false);

  const handleVisiblePassword = () => {
    setVisiblePassword((prev) => !prev);
  };

  return (
    <div className="flex flex-col items-center h-screen text-center bg-base">
      <div className="w-full h-[480px] absolute top-0 bg-gradient-to-r from-[#878df7] to-[#3e24f5]" />

      <h1 className="z-10 mt-24 tracking-wider text-white heading-1">KOOKY</h1>
      <p className="z-10 mt-5 text-sm tracking-wide text-white">
        Daily K-Pop Snack
      </p>

      {/* ===== form ===== */}
      <div className="bg-white rounded-xl p-10 mx-auto w-[420px] shadow-ground z-10 text-left mt-8">
        <h2 className="heading-2">
          {type === "sign-in" && "Sign In"}
          {type === "forgot-passowrd" && "Set a new password"}
        </h2>
        <p className="text-[#a3aed0] mt-2">
          {type === "sign-in" && "Enter your email and password to sign in"}
          {type === "forgot-passowrd" &&
            "Please enter the email address that you used when creating your kooky account"}
        </p>

        <Form className="mt-5" {...args}>
          {({ isLoading }) => (
            <>
              <label
                htmlFor="email"
                className="block mb-3 text-sm font-semibold"
              >
                Email
              </label>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="mail@domain.com"
                required
                full
              />

              {type === "sign-in" && (
                <>
                  <input type="hidden" name="loginType" value="3" />
                  <label
                    htmlFor="password"
                    className="block mt-8 mb-3 text-sm font-semibold"
                  >
                    Password
                  </label>
                  <Input
                    id="password"
                    name="password"
                    type={visiblePassword ? "text" : "password"}
                    placeholder="Min. 8 characters"
                    minLength={8}
                    required
                    full
                    renderRight={() => (
                      <button type="button" onClick={handleVisiblePassword}>
                        <BsEyeFill className="text-gray-400 cursor-pointer" />
                      </button>
                    )}
                  />
                </>
              )}

              <Button
                type="submit"
                variant="primary"
                className="mt-5"
                full
                pending={isLoading}
              >
                {type === "sign-in" && "Sign In"}
                {type === "forgot-passowrd" && "Submit"}
              </Button>
              {type === "sign-in" && (
                <div
                  onClick={() => {
                    navigate({ to: "/reset-password" });
                  }}
                  className="flex items-center justify-center mt-5 underline cursor-pointer text-primary"
                >
                  Set a Password
                </div>
              )}
            </>
          )}
        </Form>
      </div>
    </div>
  );
}

export default AuthForm;
