import {
  QueryClient,
  QueryClientProvider,
  QueryFunction,
} from "@tanstack/react-query";
import { ReactNode } from "react";
import { request, ResponseError } from "../utils/request";

export interface ReactQueryProviderProps {
  children: ReactNode;
}

/** ===== Provier ===== */
function ReactQueryProvider({ children }: ReactQueryProviderProps) {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}

// # 기본 쿼리 함수
const defaultQueryFn: QueryFunction = async ({ queryKey }) => {
  const params = queryKey[1];
  const queryString =
    params && typeof params === "object"
      ? "?" + convertQueryString(params)
      : "";
  // console.log(queryString);
  return await request.get(queryKey[0] + queryString);
};

// # 옵션 설정
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      queryFn: defaultQueryFn,
      onError: (e) => {
        const err = e as ResponseError<unknown>;
        if (err?.response?.status === 403) {
          console.log("You don't have permission.");
          alert("You don't have permission.");
          throw new Error("You don't have permission.");
        }
        const message = err?.response?.data?.message ?? "요청에 실패하였습니다";
        alert(message);
        throw new Error(message);
      },
    },
    mutations: {
      onError: (e) => {
        const err = e as ResponseError<unknown>;
        if (err?.response?.status === 403) {
          console.log("You don't have permission.");
          alert("You don't have permission.");
          throw new Error("You don't have permission.");
        }
        const message = err?.message ?? "요청에 실패하였습니다";
        alert(message);
        throw new Error(message);
      },
    },
  },
});

// # 오브젝트를 쿼리 스트링으로 변경
const convertQueryString = (params: Record<string, any>) =>
  Object.entries(params)
    .filter(([, v]) => v !== undefined && v !== "" && v !== null)
    .map(([k, v]) => k + "=" + v)
    .join("&");

export default ReactQueryProvider;
