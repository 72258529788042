import { forwardRef, InputHTMLAttributes, LegacyRef } from "react";

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "size"> {
  /** class */
  className?: string;
  /** input의 class */
  inputClass?: string;
  /** input의 크기 */
  size?: "lg" | "md" | "sm" | "xs";
  /** true 일 경우 외곽선 유무 */
  outline?: boolean;
  /** true일 경우 최대 넓이 */
  full?: boolean;
  /** true일 경우 에러 */
  error?: boolean;
  /** 앞쪽 렌더링 */
  renderLeft?: () => React.ReactNode;
  /** 뒤쪽 렌더링 */
  renderRight?: () => React.ReactNode;
}

function Input(
  {
    className = "",
    inputClass = "",
    size = "md",
    outline = true,
    full,
    error,
    renderLeft,
    renderRight,
    ...args
  }: InputProps,
  ref: LegacyRef<HTMLInputElement>
) {
  return (
    <div
      className={`input--root ${outline ? "outline" : ""} ${
        full ? "w-full" : ""
      } ${error ? "error" : ""} ${className}`}
    >
      {renderLeft && <span className="pl-3">{renderLeft()}</span>}
      <input
        className={`size-${size} bg-transparent
          ${full ? "w-full" : ""} 
          ${renderLeft ? "pl-3" : "pl-5"}
          ${renderRight ? "pr-3" : "pr-5"}
          ${inputClass}
        `}
        ref={ref}
        {...args}
      />
      {renderRight && <span className="pr-3">{renderRight()}</span>}
    </div>
  );
}

export default forwardRef(Input);
