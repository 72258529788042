import { Navigate, Outlet } from "@tanstack/react-location";
import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
// import { userStore } from "../store/user";

export interface AuthGuardProps {}

function AuthGuard({}: AuthGuardProps) {
  const { auth } = useAuth();
  const [isAuth, setIsAuth] = useState<boolean>();

  useEffect(() => {
    setIsAuth(auth());
  }, []);

  // # 인증 전
  if (isAuth === undefined) {
    return null;
  }
  // # 인증 실패
  else if (isAuth === false) {
    return <Navigate to="/signin" replace />;
  } else {
    // # 인증 성공
    return <Outlet />;
  }
}

export default AuthGuard;
