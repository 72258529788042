import axios, {
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

export interface ResponseData<T extends unknown> {
  code?: string;
  message?: string;
  data?: T;
}

export interface ResponseError<T extends unknown> {
  code: string;
  message: string;
  response: AxiosResponse<ResponseData<T>>;
}

export interface ResponseList<T> {
  /** 메인 응답값 */
  content: T[];
  /** 전체 리스트 수 */
  totalElements: number;
}

export interface ResponseUserList<T> {
  /** 메인 응답값 */
  items: T[];
  /** 전체 리스트 수 */
  totalElements: number;
}

export interface ResponseVoteHistoryList<T> {
  /** 메인 응답값 */
  rankingTopicVoteList: T[];
  /** 전체 리스트 수 */
  totalElements: number;
}

export interface ResponsePrevoteHistoryList<T> {
  /** 메인 응답값 */
  prevoteList: T[];
  /** 전체 리스트 수 */
  totalElements: number;
}

export interface ResponseMainArtistList<T> {
  /** 메인 응답값 */
  artistList: T[];
  /** 전체 리스트 수 */
  totalElements: number;
}

type CustomResponseFormat<T = any> = {
  data: T;
  // refreshedToken?: string;
};

export interface ResponseProductHistoryListList<T> {
  productHistories: T[];
  /** 전체 리스트 수 */
  totalElements: number;
}

export interface ResponseNotificationList<T> {
  notificationList: T[];
  /** 전체 리스트 수 */
  totalElements: number;
}
export interface ResponseMostListList<T> {
  mostLikeEventList: T[];
  /** 전체 리스트 수 */
  totalElements: number;
}
export interface ResponseAdminList<T> {
  adminList: T[];
}

export interface ResponseAdminGroupList<T> {
  adminGroupList: T[];
}

export interface ResponseUserUploadEventList<T> {
  eventDtos: T[];
  totalElements: number;
}

export interface ResponsePrevoteArtistList<T> {
  artistList: T[];
  totalElements: number;
}
export interface ResponseLoovyAmountList<T> {
  loovyAmountList: T[];
}

export interface ResponseFundingList<T> {
  data: {
    list: T[];
    totalElements: number;
  }
}

export interface ResponseRewardList<T> {
  data:{
    list: T[];
    totalElements: number;
  }
}

interface CustomInstance extends AxiosInstance {
  interceptors: {
    request: AxiosInterceptorManager<AxiosRequestConfig>;
    response: AxiosInterceptorManager<AxiosResponse<CustomResponseFormat>>;
  };
  getUri(config?: AxiosRequestConfig): string;
  request<T>(config: AxiosRequestConfig): Promise<T>;
  get<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  delete<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  head<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  options<T>(url: string, config?: AxiosRequestConfig): Promise<T>;
  post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
  patch<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
}

export const request: CustomInstance = axios.create({
  baseURL: import.meta.env.VITE_KOTLIN_API_HOST,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

/** 응답 인터셉터 */
request.interceptors.response.use(
  function (res) {
    return res.data;
  },
  async (error: any) => {
    const originalRequest = error.config;
    console.log("originalRequest", originalRequest);
    if (error.response && error.response.status === 401) {
      if (originalRequest.url === "/admin/user/signin") {
        alert("Please confirm your email, password");
        return Promise.reject(error);
      }
      if (originalRequest.url === "/admin/user/reauth") {
        await request.get("user/signout");
        sessionStorage.clear();
        window.location.href = "/singin";
      } else {
        try {
          // refresh token 발급 로직 추가
          const res: any = await request.get("/admin/user/reauth");
          sessionStorage.setItem("token", res?.data?.token);
          originalRequest.headers["X-AUTH-TOKEN"] = res?.data?.token;
          setToken(res?.data?.token);
          return request(originalRequest);
        } catch (error) {
          console.log(error);
        }
      }
    }
    return Promise.reject(error);
  }
);

/**
 * 인증정보를 헤더에 입력한다
 * @param accessKey{string} 인증키
 */
export const setToken = (accessKey: string) => {
  request.defaults.headers.common["X-AUTH-TOKEN"] = accessKey;
};

/**
 * 인증헤더를 제거한다
 */
export const clearToken = () => {
  request.defaults.headers.common["X-AUTH-TOKEN"] = "";
};

/**
 * 응답이 {content: {...}, code, message} 형태일 경우 {...} 형태로 변경
 */
export const responseFilter = <T extends unknown>(res: unknown): T => {
  const { content } = res as ResponseData<T> as { content: unknown };
  const returnData = content as T;

  return returnData;
};

/**
 * 리스트에 맞는 형태로 변경
 */
export const responseListFilter = <T extends unknown>(res: unknown) => {
  const { content = [], totalElements } = res as ResponseList<T>;

  return {
    list: content,
    total: totalElements ?? 0,
  };
};

/**
 * 리스트에 맞는 형태로 변경
 */
export const responseUserListFilter = <T extends unknown>(res: unknown) => {
  const { items = [], totalElements } = res as ResponseUserList<T>;

  return {
    list: items,
    total: totalElements ?? 0,
  };
};

export const responseArtistListFilter = <T extends unknown>(res: unknown) => {
  const { artistList = [], totalElements } = res as any;

  return {
    list: artistList,
    total: totalElements ?? 0,
  };
};

export const responseProductListFilter = <T extends unknown>(res: unknown) => {
  const { products = [], totalElements } = res as any;

  return {
    products: products,
    total: totalElements ?? 0,
  };
};

export const responseLuckyDrawListFilter = <T extends unknown>(
  res: unknown
) => {
  const { luckyDraws = [], totalElements } = res as any;

  return {
    luckyDraws: luckyDraws,
    total: totalElements ?? 0,
  };
};

export const responseLoovyHistoryFilter = <T extends unknown>(res: unknown) => {
  const { loovyHistory = [], totalElements } = res as any;

  return {
    list: loovyHistory,
    total: totalElements ?? 0,
  };
};

export const responseEventListFilter = <T extends unknown>(res: unknown) => {
  const { eventDtos = [], totalElements } = res as any;
  return {
    eventDtos: eventDtos,
    total: totalElements ?? 0,
  };
};

export const responseMissionPostFilter = <T extends unknown>(res: unknown) => {
  const { missionPostEventList = [], totalElements } = res as any;
  return {
    missionPostEventList: missionPostEventList,
    total: totalElements ?? 0,
  };
};

export const responsePurchaseListFilter = <T extends unknown>(res: unknown) => {
  const { purchaseEventList = [], totalElements } = res as any;
  return {
    purchaseEventList: purchaseEventList,
    total: totalElements ?? 0,
  };
};

export const responseVoteHistoryListFilter = <T extends unknown>(
  res: unknown
) => {
  const { rankingTopicVoteList = [], totalElements } =
    res as ResponseVoteHistoryList<T>;
  return {
    rankingTopicVoteList: rankingTopicVoteList,
    total: totalElements ?? 0,
  };
};

export const responsePrevoteHistoryListFilter = <T extends unknown>(
  res: unknown
) => {
  const { prevoteList = [], totalElements } =
    res as ResponsePrevoteHistoryList<T>;
  return {
    prevoteHistoryList: prevoteList,
    total: totalElements ?? 0,
  };
};

export const responseEventJoinListFilter = <T extends unknown>(
  res: unknown
) => {
  const { eventJoinList = [], totalElements } = res as any;
  return {
    list: eventJoinList,
    total: totalElements ?? 0,
  };
};

export const responsePreVoteArtistListFilter = <T extends unknown>(
  res: unknown
) => {
  const { artistList = [], totalElements } = res as any;
  return {
    preVoteArtists: artistList,
    total: totalElements ?? 0,
  };
};

export const responsePreVoteListFilter = <T extends unknown>(res: unknown) => {
  const { prevotes = [], totalElements } = res as any;
  return {
    preVoteList: prevotes,
    total: totalElements ?? 0,
  };
};

export const responseVoteArtistListFilter = <T extends unknown>(
  res: unknown
) => {
  const { rankingTopicArtistList = [], totalElements } = res as any;
  return {
    voteArtistList: rankingTopicArtistList,
    total: totalElements ?? 0,
  };
};

export const responseKookydexListFilter = <T extends unknown>(res: unknown) => {
  const { kookyDexList = [], totalElements } = res as any;
  return {
    kookydexList: kookyDexList,
    total: totalElements ?? 0,
  };
};

export const responseAdvertisementListFilter = <T extends unknown>(
  res: unknown
) => {
  const { advertisementList = [], totalElements } = res as any;
  return {
    advertisementList: advertisementList,
    total: totalElements ?? 0,
  };
};

export const responsePrevoteAdListFilter = <T extends unknown>(
  res: unknown
) => {
  const { prevoteAdList = [], totalElements } = res as any;
  return {
    prevoteAdList: prevoteAdList,
    total: totalElements ?? 0,
  };
};

export const responseAdDashboardBarFilter = <T extends unknown>(
  res: unknown
) => {
  const { adBarChart = {} } = res as any;
  return {
    adBarChartData: adBarChart.adBarChartData,
  };
};

export const responsePostListFilter = <T extends unknown>(res: unknown) => {
  const { items = [], totalElements } = res as any;
  return {
    postList: items,
    total: totalElements ?? 0,
  };
};

export const responseProductHistoryListFilter = <T extends unknown>(
  res: unknown
) => {
  const { productHistories = [], totalElements } =
    res as ResponseProductHistoryListList<T>;

  return {
    productHistoryList: productHistories,
    total: totalElements ?? 0,
  };
};

export const responseNotificationListFilter = <T extends unknown>(
  res: unknown
) => {
  const { notificationList = [], totalElements } =
    res as ResponseNotificationList<T>;
  return {
    notificationList: notificationList,
    total: totalElements ?? 0,
  };
};

export const responseMostListListFilter = <T extends unknown>(res: unknown) => {
  const { mostLikeEventList = [], totalElements } =
    res as ResponseMostListList<T>;
  return {
    mostLikeEventList: mostLikeEventList,
    total: totalElements ?? 0,
  };
};

export const responseAdminListFilter = <T extends unknown>(res: unknown) => {
  const { adminList = [] } = res as ResponseAdminList<T>;
  return {
    adminList: adminList,
  };
};

export const responseAdminGroupListFilter = <T extends unknown>(
  res: unknown
) => {
  const { adminGroupList = [] } = res as ResponseAdminGroupList<T>;
  return {
    adminGroupList: adminGroupList,
  };
};

export const responseMainArtistListFilter = <T extends unknown>(
  res: unknown
) => {
  const { artistList = [], totalElements } = res as ResponseMainArtistList<T>;

  return {
    list: artistList,
    total: totalElements ?? 0,
  };
};

export const responseUserUploadEventListFilter = <T extends unknown>(
  res: unknown
) => {
  const { eventDtos = [], totalElements } =
    res as ResponseUserUploadEventList<T>;
  return {
    eventDtos: eventDtos,
    total: totalElements ?? 0,
  };
};

export const responsePrevoteArtistListFilter = <T extends unknown>(
  res: unknown
) => {
  const { artistList = [], totalElements } =
    res as ResponsePrevoteArtistList<T>;

  return {
    list: artistList,
    total: totalElements ?? 0,
  };
};

export const responseLoovyAmountListFilter = <T extends unknown>(
  res: unknown
) => {
  const { loovyAmountList = [] } = res as ResponseLoovyAmountList<T>;

  return {
    list: loovyAmountList,
  };
};

export const responsefundingFilter = <T extends unknown>(
  res: unknown
) => {
  const { data:{ list = [], totalElements }} = res as ResponseFundingList<T>;

  return {
    list,
    total: totalElements ?? 0,
  };
};

export const responserewardFilter = <T extends unknown>(
  res: unknown
) => {
  const { data: { list = [], totalElements } } = res as ResponseFundingList<T>;

  return {
    list,
    total: totalElements ?? 0,
  };
};

