import { useMutation, UseMutationResult } from "@tanstack/react-query";
import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse } from "axios";
import React, { FormHTMLAttributes, useEffect, useRef, useState } from "react";
import { request } from "../../../utils/request";

// type FormValue  = string | File|

export interface FormProps
  extends Omit<
    FormHTMLAttributes<HTMLFormElement>,
    "action" | "method" | "children" | "onError" | "onSubmit"
  > {
  /** 기본 URL */
  // baseURL?: string;
  /** 리퀘스트 url */
  action?: string;
  /** HTTP 메소드 */
  method?: "POST" | "PUT" | "PATCH" | "DELETE";
  /** true 일 경우 성공시 폼 리셋 */
  reset?: boolean;
  /** 리퀘스트 헤더 */
  headers?: AxiosRequestHeaders;
  /** 내부요소 */
  children?:
    | ((mutationResult: MutationResult) => React.ReactNode)
    | React.ReactNode;
  /** submit 핸들러. false 반환시 리퀘스트 중단 */
  onSubmit?: (values?: unknown) => void | boolean | unknown;
  /** 성공 이벤트 핸들러 */
  onSuccess?:
    | ((
        data: AxiosResponse<any, any>,
        variables: void,
        context: unknown
      ) => void | Promise<unknown>)
    | undefined;
  /** 실패 이벤트 핸들러 */
  onError?:
    | ((
        error: unknown,
        variables: void,
        context: unknown
      ) => void | Promise<unknown>)
    | undefined;
  /** 모든 응답 이벤트 핸들러 */
  onSettled?:
    | ((
        data: AxiosResponse<any, any> | undefined,
        error: unknown,
        variables: void,
        context: unknown
      ) => void | Promise<unknown>)
    | undefined;
}

type MutationResult = UseMutationResult<
  AxiosResponse<any, any>,
  unknown,
  void,
  unknown
>;

function Form({
  // baseURL,
  action,
  method = "POST",
  reset,
  headers,
  children,
  onSubmit,
  onSuccess,
  onError,
  onSettled,
  ...args
}: FormProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const cancelRef = useRef<{ cancel?(): void }>({});
  const [formData, setFormData] = useState<Record<string, unknown> | null>(
    null
  );
  const mutation = useMutation(
    () => {
      const CancelToken = axios.CancelToken; // 취소 토큰 생성
      const source = CancelToken.source();
      const promise = request({
        // baseURL,
        method,
        url: action,
        headers,
        data: formData,
        cancelToken: source.token,
      });

      cancelRef.current.cancel = () => {
        source.cancel("Query was cancelled");
      };
      return promise;
    },
    {
      onSuccess: (...args) => {
        if (reset === true) {
          formRef.current?.reset();
        }
        if (typeof onSuccess === "function") {
          onSuccess(...args);
        }
      },
      onError: (...args) => {
        if (typeof onError === "function") {
          onError(...args);
        } else {
          const res = args[0] as AxiosError;
          console.log(res);
          if (res.response?.status === 403) {
            alert("You don't have permission.");
            return;
          }
          if (res?.message) {
            alert(res.message);
          } else {
            alert("요청에 실패하였습니다.");
          }
        }
      },
      onSettled: (...res) => {
        setFormData(null);
        if (typeof onSettled === "function") {
          onSettled(...res);
        }
      },
    }
  );
  const { mutate } = mutation;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.target as HTMLFormElement;
    let values = [...new FormData(form).entries()].reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {}
    );

    if (typeof onSubmit === "function") {
      const newValues = onSubmit(values);
      if (newValues === false) {
        return;
      }
      if (typeof newValues === "object") {
        values = newValues as object;
      }
    }

    setFormData(values);
  };

  /** 리퀘스트 요청 */
  useEffect(() => {
    if (!formData) {
      return;
    }
    mutate();
  }, [formData]);

  /** 언마운트 시 리퀘스트 캔슬 */
  useEffect(() => {
    return () => {
      // console.log(cancelRef.current.cancel);
      if (typeof cancelRef.current.cancel === "function") {
        cancelRef.current.cancel();
      }
    };
  }, []);

  return (
    <form {...args} ref={formRef} onSubmit={handleSubmit}>
      {typeof children === "function" ? children(mutation) : children}
    </form>
  );
}

export default Form;
