import DialogProvider from "./DialogProvider";
import ReactQueryProvider from "./ReactQueryProvider";
import SnackbarProvider from "./SnackbarProvider";

interface ProvidersProps {
  children: React.ReactNode;
}

function Providers({ children }: ProvidersProps) {
  return (
    <DialogProvider>
      <ReactQueryProvider>
        <SnackbarProvider>{children}</SnackbarProvider>
      </ReactQueryProvider>
    </DialogProvider>
  );
}

export default Providers;
