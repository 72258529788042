import {
  MakeGenerics,
  ReactLocation,
  Router as ReactRouter,
} from "@tanstack/react-location";
import { Content } from "../apis/contentsApis";
import AuthGuard from "../guards/AuthGuard";
import SignIn from "../pages/SignIn";
import ResetPassword from "../pages/ResetPassword";

export interface ListLoadData<T> {
  list: T[];
  total: number;
}

export type LocationGenerics = MakeGenerics<{
  Search: {
    itemsPerPage?: number;
  };
  LoaderData: {
    contents: ListLoadData<Content>;
  };
}>;

function Router() {
  return (
    <ReactRouter
      location={location}
      routes={[
        {
          path: "/signin",
          element: <SignIn />,
        },
        {
          path: "/reset-password",
          element: <ResetPassword />,
        },
        {
          element: <AuthGuard />,

          children: [
            {
              element: async () =>
                import("../components/common/MainLayout").then((module) => (
                  <module.default />
                )),
              pendingElement: async () => (
                <span className="text-xs font-bold">Loading...</span>
              ),
              children: [
                /** ===== home ===== */
                {
                  path: "/",
                  element: async () =>
                    import("../pages/Home").then((module) => (
                      <module.default />
                    )),
                },
                /** ===== contents ===== */
                {
                  path: "contents",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/Contents").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                /** ===== Magazines ===== */
                {
                  path: "magazines",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/Magazines").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/Magazines/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":magazineId/edit",
                      element: async () =>
                        import("../pages/Magazines/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                /** ===== KookyTV ===== */
                {
                  path: "kookytv",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/KookyTV").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/KookyTV/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":id/edit",
                      element: async () =>
                        import("../pages/KookyTV/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                /** ===== Vote ===== */
                {
                  path: "vote",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/Vote").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/Vote/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":id/edit",
                      element: async () =>
                        import("../pages/Vote/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                /** ===== Images ===== */
                {
                  path: "banners",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/Banners").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/Banners/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":id/edit",
                      element: async () =>
                        import("../pages/Banners/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                /** ===== Collections ===== */
                {
                  path: "collections",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/Collections").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/Collections/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":id/edit",
                      element: async () =>
                        import("../pages/Collections/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                {
                  path: "hashtag-collections",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/HashtagCollections").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/HashtagCollections/Add").then(
                          (module) => <module.default />
                        ),
                    },
                    {
                      path: ":id/edit",
                      element: async () =>
                        import("../pages/HashtagCollections/Edit").then(
                          (module) => <module.default />
                        ),
                    },
                  ],
                },
                {
                  path: "discover-hashtags",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/DiscoverHashtags").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                {
                  path: "discover-contents",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/DiscoverContents").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                /** ===== Posts ===== */
                {
                  path: "posts",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/Posts").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "dashboard",
                      element: async () =>
                        import("../pages/Posts/Dashboard").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":id",
                      element: async () =>
                        import("../pages/Posts/View").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                /** ===== Users ===== */
                {
                  path: "users",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/Users").then((module) => (
                          <module.default />
                        )),
                    },

                    {
                      path: "dashboard",
                      element: async () =>
                        import("../pages/Users/Dashboard").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "purchases",
                      element: async () =>
                        import("../pages/Users/Purchases").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":userId/edit",
                      element: async () =>
                        import("../pages/Users/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                /** ===== Artists ===== */
                {
                  path: "artists",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/Artists").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/Artists/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":userArtistInfoId/edit",
                      element: async () =>
                        import("../pages/Artists/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "dashboard",
                      element: async () =>
                        import("../pages/Artists/Dashboard").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                /** ===== Announcements ===== */
                {
                  path: "announcements",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/Announcements").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/Announcements/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":id/edit",
                      element: async () =>
                        import("../pages/Announcements/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                /** ===== Reports ===== */
                {
                  path: "reports",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/Reports").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":id/edit",
                      element: async () =>
                        import("../pages/Reports/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                /** ===== FAQ ===== */
                {
                  path: "faq",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/Faq").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/Faq/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":id/edit",
                      element: async () =>
                        import("../pages/Faq/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                /** ===== product ===== */
                {
                  path: "products",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/Products").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/Products/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":productId/edit",
                      element: async () =>
                        import("../pages/Products/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                /** ===== luckydraw ===== */
                {
                  path: "luckydraw",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/LuckyDraw").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/LuckyDraw/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":luckyDrawId/edit",
                      element: async () =>
                        import("../pages/LuckyDraw/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                /** ===== mostLike ===== */
                {
                  path: "mostlikes",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/UserEvent/MostLike").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                /** ===== missionpost ===== */
                {
                  path: "missionpost",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/UserEvent/PostMission").then(
                          (module) => <module.default />
                        ),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/UserEvent/PostMission/Add").then(
                          (module) => <module.default />
                        ),
                    },
                    {
                      path: ":id/edit",
                      element: async () =>
                        import("../pages/UserEvent/PostMission/Edit").then(
                          (module) => <module.default />
                        ),
                    },
                  ],
                },
                /** ===== purchase ===== */
                {
                  path: "purchase",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/UserEvent/Purchase").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/UserEvent/Purchase/Add").then(
                          (module) => <module.default />
                        ),
                    },
                    {
                      path: ":groupId/edit",
                      element: async () =>
                        import("../pages/UserEvent/Purchase/Edit").then(
                          (module) => <module.default />
                        ),
                    },
                  ],
                },
                /** ===== event ===== */
                {
                  path: "event",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/Event").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/Event/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":id/edit",
                      element: async () =>
                        import("../pages/Event/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                {
                  path: "user-upload-event",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/UserUploadEvent").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":id/edit",
                      element: async () =>
                        import("../pages/UserUploadEvent/Edit").then(
                          (module) => <module.default />
                        ),
                    },
                  ],
                },
                /** ===== preVote ===== */
                {
                  path: "prevotes",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/PreVotes").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/PreVotes/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":id/edit",
                      element: async () =>
                        import("../pages/PreVotes/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                /** ===== kookydex ===== */
                {
                  path: "kookydex",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/Kookydex").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/Kookydex/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":kookyDexId/edit",
                      element: async () =>
                        import("../pages/Kookydex/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                /** ===== advertisement ===== */
                {
                  path: "advertisement",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/Advertisement").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/Advertisement/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":advertisementId/edit",
                      element: async () =>
                        import("../pages/Advertisement/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "/dashboard",
                      element: async () =>
                        import("../pages/Advertisement/Dashboard").then(
                          (module) => <module.default />
                        ),
                    },
                  ],
                },
                /** ===== prevoteconnect ===== */
                {
                  path: "prevoteconnect",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/PrevoteConnect").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":rankingPreVoteId/add",
                      element: async () =>
                        import("../pages/PrevoteConnect/Add").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                {
                  path: "notification",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/Notification").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                {
                  path: "super-admin",
                  children: [
                    {
                      path: "add-admin",
                      element: async () =>
                        import("../pages/SuperAdmin/AddAdmin").then(
                          (module) => <module.default />
                        ),
                    },
                    {
                      path: "admin",
                      element: async () =>
                        import("../pages/SuperAdmin/Admin").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "admin-group",
                      children: [
                        {
                          path: "/",
                          element: async () =>
                            import("../pages/SuperAdmin/AdminGroup").then(
                              (module) => <module.default />
                            ),
                        },
                        {
                          path: ":id/edit",
                          element: async () =>
                            import("../pages/SuperAdmin/AdminGroup/Edit").then(
                              (module) => <module.default />
                            ),
                        },
                      ],
                    },
                  ],
                },
                {
                  path: "main-artist",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/MainArtist").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/MainArtist/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":id/edit",
                      element: async () =>
                        import("../pages/MainArtist/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                // new collection
                {
                  path: "new-collections",
                  children: [
                    {
                      path: "/home",
                      element: async () =>
                        import(
                          "../pages/NewCollection/NewCollectionsHome"
                        ).then((module) => <module.default />),
                    },
                    {
                      path: "/trend",
                      element: async () =>
                        import(
                          "../pages/NewCollection/NewCollectionsTrend"
                        ).then((module) => <module.default />),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/NewCollection/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":id/edit",
                      element: async () =>
                        import("../pages/NewCollection/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                {
                  path: "prevote-artist",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/PrevoteArtist").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/PrevoteArtist/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":prevoteArtistInfoId/edit",
                      element: async () =>
                        import("../pages/PrevoteArtist/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                {
                  path: "loovy-amount",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/LoovyAmount").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                {
                  path: "create-artists",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/CreateArtists").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                //funding
                {
                  path: "funding",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/Funding").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/Funding/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":FundingId/edit",
                      element: async () =>
                        import("../pages/Funding/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
                //reward
                {
                  path: "reward",
                  children: [
                    {
                      path: "/",
                      element: async () =>
                        import("../pages/Funding/Reward").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: "add",
                      element: async () =>
                        import("../pages/Funding/Reward/Add").then((module) => (
                          <module.default />
                        )),
                    },
                    {
                      path: ":RewardId/edit",
                      element: async () =>
                        import("../pages/Funding/Reward/Edit").then((module) => (
                          <module.default />
                        )),
                    },
                  ],
                },
              ],
            },
          ],
        },
      ]}
    />
  );
}

const location = new ReactLocation<LocationGenerics>();

export default Router;
