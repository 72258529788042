import React, { ButtonHTMLAttributes } from "react";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /** 버튼 형태 */
  variant?:
    | "primary"
    | "secondary"
    | "white"
    | "ghost"
    | "white"
    | "info"
    | "success"
    | "warning"
    | "error";
  /** 사이즈 */
  size?: "xs" | "sm" | "md" | "lg";
  /** `true`일 비활성화 */
  disabled?: boolean;
  /** `true`일 경우 pending */
  pending?: boolean;
  /** `true`일 경우 외곽선 */
  outlined?: boolean;
  /** `true`일 경우 전제넓이 */
  full?: boolean;
  /** `true`일 경우 원형버튼 */
  circled?: boolean;
  /** 내부 요소 */
  children?: React.ReactNode;
}

function Button({
  type = "button",
  variant = "primary",
  size = "md",
  disabled,
  pending,
  outlined,
  circled,
  full,
  children,
  className = "",
  ...args
}: ButtonProps) {
  return (
    <button
      className={`button--root button-${variant} size-${size} ${
        pending ? "pending" : ""
      } ${circled ? "circled" : ""} ${outlined ? "outlined" : ""} 
      ${full ? "full" : ""} ${className}`}
      disabled={disabled ?? pending}
      type={type}
      {...args}
    >
      {children}
    </button>
  );
}

export default Button;
