import React, { useCallback } from "react";
import create from "zustand";
import Dialog, { DialogProps } from "../components/atoms/Dialog";

interface DialogHisotryItem
  extends Omit<
    DialogProps,
    "visible" | "onMount" | "onUnmount" | "onVisible" | "onInvisible" | "onOk"
  > {
  onOk?: (colose: DialogStoreState["onClose"]) => void;
}

interface DialogStoreState extends DialogHisotryItem {
  history: DialogHisotryItem[];
  pushHistory: (data: DialogHisotryItem) => void;
  onClose: () => void;
}

/** provider */
export function DialogProvider({ children }: { children: React.ReactNode }) {
  const history = dialogStore((state) => state.history);
  const onClose = dialogStore((state) => state.onClose);
  const data = history.length > 0 ? history[history.length - 1] : undefined;

  return (
    <>
      {children}
      <Dialog
        className={data?.className}
        title={data?.title}
        content={data?.content}
        okText={data?.okText}
        cancelText={data?.cancelText}
        visible={history.length > 0}
        onOk={() => {
          if (typeof data?.onOk === "function") {
            data?.onOk(onClose);
          }
          onClose();
        }}
        onClose={onClose}
      />
    </>
  );
}

/** hook */
export function useDialog() {
  const pushHistory = dialogStore((state) => state.pushHistory);
  const dialog = useCallback(
    (data: DialogHisotryItem) => {
      pushHistory(data);
    },
    [pushHistory]
  );

  return { dialog };
}

/** store */
const dialogStore = create<DialogStoreState>((set, get) => ({
  history: [],
  pushHistory: (data) =>
    set((state) => ({ history: [...state.history, data] })),
  onClose: () => {
    const history = [...get().history];
    const data = history.pop();

    if (typeof data?.onClose === "function") {
      data.onClose();
    }
    set({ history: history });
  },
}));

export default DialogProvider;
