import { useCallback } from "react";
import { User, userStore } from "../store/user";
import { setToken } from "../utils/request";

export interface LoginParams extends User {
  /** access token  */
  token: string;
}
export function useAuth() {
  const setUser = userStore((s) => s.setUser);

  // # login
  const login = useCallback(
    ({ token, id, name, userType, role, roles }: LoginParams) => {
      // console.log(token, id, name, userType);
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("id", String(id));
      sessionStorage.setItem("name", name);
      sessionStorage.setItem("userType", userType);
      sessionStorage.setItem("role", role);
      sessionStorage.setItem("roles", JSON.stringify(roles) || "[]");
      setUser({ id, name, userType, role, roles });
      setToken(token);
    },
    []
  );

  /** 인증 */
  const auth = useCallback(() => {
    const token = sessionStorage.getItem("token");
    const id = sessionStorage.getItem("id");
    const name = sessionStorage.getItem("name");
    const userType = sessionStorage.getItem("userType");
    const role = sessionStorage.getItem("role");
    const roles = JSON.parse(sessionStorage.getItem("roles") || "[]");
    // console.log(token);

    if (token) {
      setToken(token);
    }
    if (id && name && userType && role && roles) {
      setUser({
        id: Number(id),
        name,
        userType: userType as "ADMIN",
        role,
        roles,
      });
    }

    return !!token;
  }, []);

  return {
    login,
    auth,
  };
}
