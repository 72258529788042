import AuthForm from "../../components/auth/AuthForm";

import { useNavigate } from "@tanstack/react-location";

export default function ResetPassword() {
  const navigate = useNavigate();

  return (
    <AuthForm
      type="forgot-passowrd"
      action="/admin/user/reset-password"
      onSuccess={(res: unknown) => {
        alert("Password reset email sent. Please check your email.");
        navigate({ to: "/", replace: true });
      }}
      onError={(error) => {
        const message = error as { message: string };
        alert(message);
      }}
    />
  );
}
