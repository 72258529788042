import React, {
  MouseEventHandler,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

export interface ModalProps {
  /** 요소의 아이디. 새로 만들때는 필수 */
  rootId?: string;
  /** 요소의 최상위 class */
  className?: string;
  /** 컨텐츠가 보여질 애니메이션 형탱 */
  visibleAnimation?: "bottom-top" | "top-bottom" | "none";
  /** true일 경우 배경 요소를 없앤다 */
  backgroundNone?: boolean;
  /** false일 경우 기본 스타일을 적용안함 */
  styled?: boolean;
  /** true 일 경우 활성화 */
  visible?: boolean;
  /** true일 경우 넓이를 고정 시키다 */
  lockWidth?: boolean;
  /** false일 경우 body를 overflow-hidden 처리 하지 않는다 */
  bodyOverflowHidden?: boolean;
  /** 랜더링 노드 */
  children?: React.ReactNode;
  /** 마운트 시 이벤트 핸들러 */
  onMount?(ref: MutableRefObject<HTMLElement | undefined>): void;
  /** 언마운트 시 이벤트 핸들러 */
  onUnmount?(): void;
  /** 활성화시 이벤트 핸들러 */
  onVisible?(ref: MutableRefObject<HTMLElement | undefined>): void;
  /** 비활성화시 이벤트 핸들러 */
  onInvisible?(): void;
  /** 배경 클릭시 이벤트 핸들러 */
  onBackdropClick?: MouseEventHandler<HTMLDivElement>;
}

function Modal({
  rootId = "modal--root",
  className = "",
  visible,
  visibleAnimation = "bottom-top",
  backgroundNone,
  styled = true,
  lockWidth,
  bodyOverflowHidden = true,
  children,
  onMount,
  onUnmount,
  onVisible,
  onInvisible,
  onBackdropClick,
}: ModalProps) {
  const ref = useRef<HTMLElement>();
  const boxRef = useRef<HTMLDivElement>(null);
  const [mounted, setMounted] = useState(false);
  const [show, setShow] = useState(false);

  const handleContentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as Node;
    if (boxRef.current?.contains(target) === true) {
      return;
    }
    onBackdropClick && onBackdropClick(e);
  };

  useEffect(() => {
    setMounted(true);
    let rootEl = document.getElementById(rootId);
    if (rootEl) {
      return;
    }
    rootEl = document.createElement("div");
    rootEl.setAttribute("id", rootId);
    rootEl.classList.add("modal--root");
    rootEl.classList.add("modal-root-" + rootId);
    document.body.appendChild(rootEl);
    ref.current = rootEl;
    onMount && onMount(ref);

    return () => {
      const rootEl = document.getElementById(rootId);
      if (rootEl && !rootEl?.innerHTML) {
        document.body.removeChild(rootEl);
      }
      ref.current = undefined;
      setMounted(false);
      onUnmount && onUnmount();
    };
  }, [onMount, onUnmount]);

  useEffect(() => {
    if (!mounted) {
      return;
    }

    const rootEl = document.body.querySelector("#root");

    if (visible === true) {
      // onVisible && onVisible(ref);
      if (bodyOverflowHidden === true) {
        document.body.classList.add("overflow-hidden");
        rootEl?.setAttribute("inert", "");
        rootEl?.setAttribute("aria-hidden", "true");
      }
    } else {
      document.body.classList.remove("overflow-hidden");
      rootEl?.removeAttribute("inert");
      rootEl?.removeAttribute("aria-hidden");
      // onInvisible && onInvisible();
    }

    setTimeout(() => {
      setShow(!!visible);
    });
  }, [mounted, visible, bodyOverflowHidden, onVisible, onInvisible]);

  useEffect(() => {
    if (visible === true) {
      onVisible && onVisible(ref);
    } else {
      onInvisible && onInvisible();
    }
  }, [visible]);

  if (ref.current && visible === true) {
    return createPortal(
      <div
        className={`modal--container ${styled ? "styled" : ""} ${
          show ? "show" : ""
        } ${className}`}
      >
        {!backgroundNone && <div className={`modal--backdrop`} />}

        <div
          className={`modal--content visible-${visibleAnimation}`}
          onClick={handleContentClick}
        >
          <div
            className={`content--box ${lockWidth ? "max-w-[600px]" : ""}`}
            ref={boxRef}
          >
            {children}
          </div>
        </div>
      </div>,
      ref.current
    );
  }

  return null;
}

export default React.memo(Modal);
