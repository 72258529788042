import { useNavigate } from "@tanstack/react-location";
import AuthForm from "../../components/auth/AuthForm";
import { LoginParams, useAuth } from "../../hooks/useAuth";

export interface SignInProps {}

function SignIn({}: SignInProps) {
  const navigate = useNavigate();
  const { login } = useAuth();

  return (
    <AuthForm
      type="sign-in"
      action="/admin/user/signin"
      onSuccess={(res: unknown) => {
        const loginParams = res as LoginParams;

        login(loginParams);
        navigate({ to: "/", replace: true });
      }}
      onError={(error) => {
        const message = error as { message: string };
        alert("로그인에 실패 하였습니다\n" + message);
      }}
    />
  );
}

export default SignIn;
