import dayjs from "dayjs";
import "dayjs/locale/en";

import Providers from "./providers";
import Router from "./routes";
import { BrowserRouter } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

dayjs.locale("en");

function App() {
  return (
    <Providers>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={true} />
    </Providers>
  );
}

export default App;
