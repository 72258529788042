import { useCallback, useEffect, useState } from "react";
import create from "zustand";
import Modal from "../../components/atoms/Modal";
import Snackbar, {
  SnackbarProps,
} from "../../components/compositions/Snackbar";

export interface SnackbarProviderProps {
  /** 메시지가 보여질 기간 */
  visiblePeriod?: number;
  /** 자식 요소 */
  children?: React.ReactNode;
}

interface StoreState {
  items: Item[];
  push(item: Item): void;
  onClose(id: string): void;
}

export interface Item {
  id: string;
  message: SnackbarProps["children"];
  variant: SnackbarProps["variant"];
}

/** ===== provider =====  */
function SnackbarProvider({
  visiblePeriod = 3000,
  children,
}: SnackbarProviderProps) {
  const items = store((state) => state.items);
  const onClose = store((state) => state.onClose);

  useEffect(() => {
    let timeFn: NodeJS.Timeout;
    const removeIntervar = () => {
      if (items.length === 0) {
        return;
      }
      if (timeFn) {
        clearTimeout(timeFn);
      }
      timeFn = setTimeout(() => {
        if (items.length === 0) {
          return;
        }
        onClose(items[items.length - 1].id);
      }, visiblePeriod);
    };
    removeIntervar();
  }, [items, onClose]);

  return (
    <>
      {children}
      <Modal
        rootId="snackbar--modal"
        visible={!!(items?.length > 0)}
        bodyOverflowHidden={false}
        styled={false}
      >
        <div className="fixed w-full h-0">
          {items?.map(({ id, message, variant }) => (
            <Snackbar
              className="mx-auto mt-3 animate-from-top"
              key={id}
              variant={variant}
              onClose={() => onClose(id)}
            >
              {message}
            </Snackbar>
          ))}
        </div>
      </Modal>
    </>
  );
}

/** ===== hook ===== */
export const useSnackbar = () => {
  const push = store((state) => state.push);
  const snackbar = useCallback(
    (message: Item["message"], options?: Omit<Item, "message" | "id">) => {
      const id = Math.random().toString(16).substring(2);

      push({ message, id, variant: options?.variant });
    },
    []
  );

  return { snackbar };
};

/** ===== store ===== */
const store = create<StoreState>((set) => ({
  items: [],
  push: (item) => set((state) => ({ items: [item, ...state.items] })),
  onClose: (id) => {
    set((state) => ({ items: state.items.filter((a) => a.id !== id) }));
  },
}));

export default SnackbarProvider;
