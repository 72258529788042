import { HTMLAttributes } from "react";
import { BsXCircle } from "react-icons/bs";
import Button from "../../atoms/Button";

export interface SnackbarProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  variant?:
    | "primary"
    | "secondary"
    | "ghost"
    | "info"
    | "success"
    | "warning"
    | "error";
  children?: React.ReactNode;
  onClose?: () => void;
}

function Snackbar({
  variant = "primary",
  className = "",
  children,
  onClose,
  ...args
}: SnackbarProps) {
  return (
    <div
      className={`bg-${variant} flex justify-between items-center py-2 w-96 rounded-xl pl-4
        break-word font-light shadow-ground ${
          variant === "ghost"
            ? "text-gray-700 border border-gray-200 bg-white"
            : "text-white"
        }
        ${className}
      `}
      {...args}
    >
      <p className="line-clamp-3 flex-1">{children}</p>
      <Button
        className="mx-2 w-6"
        variant={variant}
        size="xs"
        circled
        onClick={onClose}
      >
        <BsXCircle fontSize="1rem" />
      </Button>
    </div>
  );
}
export default Snackbar;
