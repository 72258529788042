import React from "react";
import Button, { ButtonProps } from "../Button";
import Modal, { ModalProps } from "../Modal";

export interface DialogProps extends Omit<ModalProps, "onBackdropClick"> {
  /** class */
  className?: string;
  /** 타이틀 */
  title?: React.ReactNode;
  /** 내용 */
  content?: React.ReactNode;
  /** 확인 버튼 텍스트 */
  okText?: React.ReactNode;
  /** 취소 버튼 텍스트 */
  cancelText?: React.ReactNode;
  /** 확인 버튼 클릭 이벤트 핸들러 */
  onOk?: ButtonProps["onClick"];
  /** 취소 버튼 클릭 이벤트 핸들러 */
  onClose?: () => void;
}

function Dialog({
  className = "",
  title,
  content,
  okText = "확인",
  cancelText = "취소",
  onOk,
  onClose,
  ...args
}: DialogProps) {
  return (
    <Modal onBackdropClick={onClose} {...args}>
      <div className={`bg-white w-96 text-gray-600 ${className}`} role="dialog">
        {title && (
          <h4 className="heading-4 h-12 px-4 flex items-center truncate">
            {title}
          </h4>
        )}
        <div className="border-b border-gray-200"></div>
        <div className="dialog--content p-4 h-">{content}</div>
        <div className="flex justify-end space-x-3 p-4">
          {onClose && (
            <Button autoFocus tabIndex={1} variant="ghost" onClick={onClose}>
              {cancelText}
            </Button>
          )}
          {onOk && (
            <Button autoFocus tabIndex={0} onClick={onOk}>
              {okText}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
export default React.memo(Dialog);
