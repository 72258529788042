import create from "zustand";

export interface UserStore {
  /** 유저 */
  user?: User;
  /** 유저 셋 */
  setUser(user: User): void;
  /** 유저 정보 초기화 */
  initialize(): void;
}

export interface User {
  /** 아이디 */
  id: number;
  /** 이름 */
  name: string;
  /** 유저 타입 */
  userType: "ADMIN";
  /** 어드민 타입 */
  role: string;
  /** 어드민 권한 */
  roles: string[];
}

export const userStore = create<UserStore>((set) => ({
  user: undefined,
  setUser: (user) => set(() => ({ user })),
  initialize: () => set({ user: undefined }),
}));
